import { SVGProps } from 'react';

const ChevronUp = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.06 5.26996L8 4.32996L4 0.329956L0 4.32996L0.94 5.26996L4 2.21662L7.06 5.26996Z"
        fill="black"
      />
    </svg>
  );
};

export default ChevronUp;
