import { SVGProps } from 'react';

const InsertLink = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.6 3.99996C1.6 2.85996 2.52667 1.93329 3.66667 1.93329H6.33333V0.666626H3.66667C1.82667 0.666626 0.333333 2.15996 0.333333 3.99996C0.333333 5.83996 1.82667 7.33329 3.66667 7.33329H6.33333V6.06663H3.66667C2.52667 6.06663 1.6 5.13996 1.6 3.99996ZM4.33333 4.66663H9.66667V3.33329H4.33333V4.66663ZM10.3333 0.666626H7.66667V1.93329H10.3333C11.4733 1.93329 12.4 2.85996 12.4 3.99996C12.4 5.13996 11.4733 6.06663 10.3333 6.06663H7.66667V7.33329H10.3333C12.1733 7.33329 13.6667 5.83996 13.6667 3.99996C13.6667 2.15996 12.1733 0.666626 10.3333 0.666626Z"
        fill="black"
      />
    </svg>
  );
};

export default InsertLink;
