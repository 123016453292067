import { SVGProps } from 'react';

const ErrorOutline = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 3.66671C7.36667 3.66671 7.66667 3.96671 7.66667 4.33337V7.00004C7.66667 7.36671 7.36667 7.66671 7 7.66671C6.63334 7.66671 6.33334 7.36671 6.33334 7.00004V4.33337C6.33334 3.96671 6.63334 3.66671 7 3.66671ZM6.99334 0.333374C3.31334 0.333374 0.333336 3.32004 0.333336 7.00004C0.333336 10.68 3.31334 13.6667 6.99334 13.6667C10.68 13.6667 13.6667 10.68 13.6667 7.00004C13.6667 3.32004 10.68 0.333374 6.99334 0.333374ZM7 12.3334C4.05334 12.3334 1.66667 9.94671 1.66667 7.00004C1.66667 4.05337 4.05334 1.66671 7 1.66671C9.94667 1.66671 12.3333 4.05337 12.3333 7.00004C12.3333 9.94671 9.94667 12.3334 7 12.3334ZM7.66667 10.3334H6.33334V9.00004H7.66667V10.3334Z"
        fill="black"
      />
    </svg>
  );
};

export default ErrorOutline;
