import { SVGProps } from 'react';

const Warning = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 13"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.666668 13H15.3333L8 0.333374L0.666668 13ZM2.98 11.6667L8 2.99337L13.02 11.6667H2.98ZM7.33333 9.66671H8.66667V11H7.33333V9.66671ZM7.33333 5.66671H8.66667V8.33337H7.33333V5.66671Z"
        fill="black"
      />
    </svg>
  );
};

export default Warning;
