import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const ResetPasswordContent = lazy(
  () => import('src/features/auth0/ResetPassword/ResetPasswordContent')
);

const ForgotPasswordConfirmation = lazy(
  () => import('src/features/auth0/ForgotPassword/ForgotPasswordConfirmation')
);

const SignInContent = lazy(
  () => import('src/features/auth0/sign-in/SignInContent/SignInContent')
);

const ForgotPasswordContent = lazy(
  () => import('src/features/auth0/ForgotPassword/ForgotPasswordContent')
);

const Auth0App = () => {
  return (
    <Routes>
      <Route path="/login" element={<SignInContent />} />
      <Route path="/lo/reset" element={<ResetPasswordContent />} />
      <Route path="/change-password" element={<ForgotPasswordContent />} />
      <Route
        path="/change-password/confirmation"
        element={<ForgotPasswordConfirmation />}
      />
    </Routes>
  );
};

export default Auth0App;
