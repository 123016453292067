import { SVGProps } from 'react';

const Mail = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="black" {...props}>
      <path
        d="M14.667 4c0-.733-.6-1.333-1.334-1.333H2.667c-.734 0-1.334.6-1.334 1.333v8c0 .733.6 1.333 1.334 1.333h10.666c.734 0 1.334-.6 1.334-1.333V4Zm-1.334 0L8 7.333 2.667 4h10.666Zm0 8H2.667V5.333L8 8.667l5.333-3.334V12Z"
        fill="inherit"
      />
    </svg>
  );
};

export default Mail;
