import { SVGProps } from 'react';

const ChevronRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.73 7.06L1.67 8L5.67 4L1.67 0L0.73 0.94L3.78333 4L0.73 7.06Z"
        fill="black"
      />
    </svg>
  );
};

export default ChevronRight;
