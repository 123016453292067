import { SVGProps } from 'react';

const ChevronDown = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.94 0.729981L0 1.66998L4 5.66998L8 1.66998L7.06 0.72998L4 3.78331L0.94 0.729981Z"
        fill="black"
      />
    </svg>
  );
};

export default ChevronDown;
