import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { StrictMode } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Auth0App from './features/auth0/Auth0App';

import './index.css';

const GRAPHQL_SERVER_URL = 'https://flyby-gateway.herokuapp.com/';

const client = new ApolloClient({
  uri: GRAPHQL_SERVER_URL,
  cache: new InMemoryCache(),
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <BrowserRouter>
      <Auth0App />

      <Auth0Provider
        domain={import.meta.env.VITE_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
        redirectUri={import.meta.env.VITE_AUTH0_REDIRECT_URI}
        audience={import.meta.env.VITE_AUTH0_AUDIENCE}
        useRefreshTokens
      >
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </Auth0Provider>
    </BrowserRouter>
  </StrictMode>
);
