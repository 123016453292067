export interface Rule {
  id: string;
  label: string;
  regex: RegExp;
  checked: boolean;
}

export const STRENGTH = {
  WEAK: {
    label: 'Weak',
    color: 'danger-500',
    progress: '15%',
  },
  MEDIUM: {
    label: 'Medium',
    color: 'warning-500',
    progress: '50%',
  },
  STRONG: {
    label: 'Strong',
    color: 'success-500',
    progress: '85%',
  },
};

export type PasswordStrengthType = (typeof STRENGTH)[keyof typeof STRENGTH];

export const PASSWORD_RULES: Rule[] = [
  {
    id: 'lowercase',
    label: 'Lower case letters (a-z)',
    regex: /[a-z]/,
    checked: false,
  },
  {
    id: 'uppercase',
    label: 'Upper case letters (A-Z)',
    regex: /[A-Z]/,
    checked: false,
  },
  {
    id: 'number',
    label: 'Numbers (1-9)',
    regex: /[0-9]/,
    checked: false,
  },
  {
    id: 'min-length',
    label: 'At least 8 characters in length',
    regex: /^.{8,}$/,
    checked: false,
  },
];

export const getPasswordStrength = (rules: Rule[]) => {
  const score = rules.filter((r) => r.checked).length;

  if (score <= rules.length / 2) {
    return STRENGTH.WEAK;
  }
  if (score > rules.length / 2 && score < rules.length) {
    return STRENGTH.MEDIUM;
  }
  return STRENGTH.STRONG;
};
