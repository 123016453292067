import { SVGProps } from 'react';

const Close = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="black" {...props}>
      <g clipPath="url(#a)">
        <path
          d="m12.667 4.273-.94-.94L8 7.06 4.273 3.333l-.94.94L7.06 8l-3.727 3.727.94.94L8 8.94l3.727 3.727.94-.94L8.94 8l3.727-3.727Z"
          fill="inherit"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="inherit" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Close;
