import { Placement, Options } from '@popperjs/core';
import { PopperRefElement } from './types';

const PLACEMENTS: { [key: string]: Placement } = {
  top: 'top',
  'top-start': 'top-start',
  'top-end': 'top-end',
  bottom: 'bottom',
  'bottom-start': 'bottom-start',
  'bottom-end': 'bottom-end',
  right: 'right',
  'right-start': 'right-start',
  'right-end': 'right-end',
  left: 'left',
  'left-start': 'left-start',
  'left-end': 'left-end',
};

const getFallbackPlacements = (placement: string) =>
  Object.values(PLACEMENTS).filter((p) => p !== placement);

const getPopperConfig = (
  arrowEl: PopperRefElement,
  placement = 'top'
): Options => {
  return {
    strategy: 'fixed',
    modifiers: [
      { name: 'arrow', options: { element: arrowEl } },
      { name: 'offset', options: { offset: [0, 8] } },
      {
        name: 'flip',
        options: { fallbackPlacements: getFallbackPlacements(placement) },
      },
    ],
    placement: PLACEMENTS[placement],
  };
};

export default getPopperConfig;
