import { SVGProps } from 'react';

const ChevronLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.27333 0.94L4.33333 0L0.333333 4L4.33333 8L5.27333 7.06L2.22 4L5.27333 0.94Z"
        fill="black"
      />
    </svg>
  );
};

export default ChevronLeft;
