import { SVGProps } from 'react';

import CheckCircleOutline from './CheckCircleOutline';
import Check from './Check';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ChevronUp from './ChevronUp';
import Circle from './Circle';
import ClearCircleOutline from './ClearCircleOutline';
import Close from './Close';
import ErrorOutline from './ErrorOutline';
import Image from './Image';
import Info from './Info';
import InsertLink from './InsertLink';
import Lock from './Lock';
import Mail from './Mail';
import Minus from './Minus';
import Person from './Person';
import PersonPin from './PersonPin';
import Visibility from './Visibility';
import VisibilityOff from './VisibilityOff';
import Warning from './Warning';

type IconType = (props: SVGProps<SVGSVGElement>) => JSX.Element;

type IconComponent = Record<string, IconType>;

export const icons: IconComponent = {
  CheckCircleOutline,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Circle,
  ClearCircleOutline,
  Close,
  ErrorOutline,
  Image,
  Info,
  InsertLink,
  Lock,
  Mail,
  Minus,
  Person,
  PersonPin,
  Visibility,
  VisibilityOff,
  Warning,
};
