import { useEffect, useState } from 'react';
import {
  PasswordStrengthType,
  Rule,
  STRENGTH,
  getPasswordStrength,
} from './util';

export default function useCheckPasswordStrength(
  password: string,
  rules: Rule[]
) {
  const [strength, setStrength] = useState<PasswordStrengthType>(STRENGTH.WEAK);
  const [rulesStatus, setRulesStatus] = useState(rules);

  useEffect(() => {
    const checkedRules = rules.map((rule) => ({
      ...rule,
      checked: rule.regex.test(password),
    }));
    setRulesStatus(checkedRules);
    const result = getPasswordStrength(checkedRules);
    setStrength(result);
  }, [password, rules]);

  return {
    strength,
    rulesStatus,
  };
}
