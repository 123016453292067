import { SVGProps } from 'react';

const Check = (props: SVGProps<SVGSVGElement>) => {
  const { fill = 'white', ...rest } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4 7.78002L1.22 5.00002L0.273331 5.94002L4 9.66669L12 1.66668L11.06 0.726685L4 7.78002Z"
        fill={fill}
      />
    </svg>
  );
};

export default Check;
