import { useAuth0 } from '@auth0/auth0-react';

const Header = () => {
  const { loginWithPopup, isAuthenticated, isLoading } = useAuth0();
  return (
    <div className="flex w-full h-[100px] bg-surface-0 justify-between">
      <div className="flex-1 self-center">
        <span className="flex justify-center ml-10">logo</span>
      </div>
      {!isAuthenticated && !isLoading && (
        <button
          type="button"
          onClick={() => {
            loginWithPopup({
              scope: 'openid',
              connection: 'Username-Password-Authentication',
            });
          }}
          className="self-center px-4 py-2 mr-10 border border-primary-300 rounded"
        >
          Sign in
        </button>
      )}
    </div>
  );
};

export default Header;
