import { SVGProps } from 'react';

const Lock = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="black" {...props}>
      <path
        d="M12 5.333h-.667V4a3.335 3.335 0 0 0-6.666 0v1.333H4c-.733 0-1.333.6-1.333 1.334v6.666c0 .734.6 1.334 1.333 1.334h8c.733 0 1.333-.6 1.333-1.334V6.667c0-.734-.6-1.334-1.333-1.334ZM6 4c0-1.107.893-2 2-2s2 .893 2 2v1.333H6V4Zm6 9.333H4V6.667h8v6.666Zm-4-2c.733 0 1.333-.6 1.333-1.333S8.733 8.667 8 8.667s-1.333.6-1.333 1.333.6 1.333 1.333 1.333Z"
        fill="inherit"
      />
    </svg>
  );
};

export default Lock;
