import { SVGProps } from 'react';

const VisibilityOff = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="black" {...props}>
      <path
        d="M8 4a6.513 6.513 0 0 1 5.88 3.667 6.431 6.431 0 0 1-1.607 2.08l.94.94a7.868 7.868 0 0 0 2.12-3.02c-1.153-2.927-4-5-7.333-5a7.9 7.9 0 0 0-2.427.38l1.1 1.1A6.74 6.74 0 0 1 8 4Zm-.713.76 1.38 1.38c.38.167.686.473.853.853l1.38 1.38c.053-.226.093-.466.093-.713A2.989 2.989 0 0 0 8 4.667c-.247 0-.48.033-.713.093ZM1.34 2.58l1.787 1.787c-1.1.86-1.95 2-2.46 3.3 1.153 2.926 4 5 7.333 5a7.82 7.82 0 0 0 2.88-.547l2.28 2.28.94-.94L2.28 1.633l-.94.947Zm5 5 1.74 1.74c-.027.007-.053.013-.08.013a1.667 1.667 0 0 1-1.667-1.666c0-.034.007-.054.007-.087ZM4.073 5.313 5.24 6.48a3.005 3.005 0 0 0 3.94 3.947l.653.653c-.586.16-1.2.253-1.833.253a6.513 6.513 0 0 1-5.88-3.666 6.601 6.601 0 0 1 1.953-2.354Z"
        fill="inherit"
      />
    </svg>
  );
};

export default VisibilityOff;
