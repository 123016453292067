import { SVGProps } from 'react';
import { IconName } from './types';
import { icons } from './icons';

interface IconProps extends SVGProps<SVGSVGElement> {
  name: IconName;
}

const Icon = (props: IconProps) => {
  const { name } = props;
  const IconComponent = icons?.[name];

  return (
    IconComponent && (
      <IconComponent
        className="inline-block"
        data-testid={`${name}-icon`}
        {...props}
      />
    )
  );
};

export default Icon;
