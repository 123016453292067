import { SVGProps } from 'react';

const PersonPin = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6667 0.333374H1.33333C0.979711 0.333374 0.640573 0.47385 0.390524 0.723898C0.140476 0.973947 0 1.31309 0 1.66671V11C0 11.3537 0.140476 11.6928 0.390524 11.9429C0.640573 12.1929 0.979711 12.3334 1.33333 12.3334H4L6 14.3334L8 12.3334H10.6667C11.4 12.3334 12 11.7334 12 11V1.66671C12 0.933374 11.4 0.333374 10.6667 0.333374ZM10.6667 11H7.44667L6 12.4467L4.55333 11H1.33333V1.66671H10.6667V11ZM6 6.33337C7.1 6.33337 8 5.43337 8 4.33337C8 3.23337 7.1 2.33337 6 2.33337C4.9 2.33337 4 3.23337 4 4.33337C4 5.43337 4.9 6.33337 6 6.33337ZM6 3.66671C6.36667 3.66671 6.66667 3.96671 6.66667 4.33337C6.66667 4.70004 6.36667 5.00004 6 5.00004C5.63333 5.00004 5.33333 4.70004 5.33333 4.33337C5.33333 3.96671 5.63333 3.66671 6 3.66671ZM10 9.38671C10 7.72004 7.35333 7.00004 6 7.00004C4.64667 7.00004 2 7.72004 2 9.38671V10.3334H10V9.38671ZM3.65333 9.00004C4.14667 8.66004 5.14 8.33337 6 8.33337C6.86 8.33337 7.85333 8.66004 8.34667 9.00004H3.65333Z"
        fill="black"
      />
    </svg>
  );
};

export default PersonPin;
