import { ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';

import { PopperRefElement } from './types';
import getPopperConfig from './utils';

import './tooltip.css';

interface TooltipProps {
  children: ReactNode;
  tooltipContent: ReactNode;
  placement?: Placement;
  visible?: boolean;
}

const Tooltip = (props: TooltipProps) => {
  const { children, tooltipContent, placement, visible = false } = props;

  const [isVisible, setVisible] = useState(visible);

  const [refEl, setRefEl] = useState<PopperRefElement>(null);
  const [popperEl, setPopperEl] = useState<PopperRefElement>(null);
  const [arrowEl, setArrowEl] = useState<PopperRefElement>(null);

  const { styles, attributes } = usePopper(
    refEl,
    popperEl,
    getPopperConfig(arrowEl, placement)
  );

  return (
    <>
      <div
        className="relative inline-block box-border"
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        ref={setRefEl}
      >
        {children}
      </div>

      {isVisible ? (
        <div
          ref={setPopperEl}
          style={styles.popper}
          className="popper-tooltip z-50 bg-surface-50 rounded-md shadow-md p-2"
          {...attributes.popper}
        >
          {tooltipContent}
          <div
            ref={setArrowEl}
            style={styles.arrow}
            className="popper-arrow"
            data-popper-arrow
          />
        </div>
      ) : null}
    </>
  );
};

export default Tooltip;
