import { ChangeEvent, FC, InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import useCheckPasswordStrength from './useCheckPasswordStrength/useCheckPasswordStrength';
import PasswordStrength from './PasswordStrength/PasswordStrength';
import Tooltip from '../Tooltip/Tooltip';
import { PASSWORD_RULES } from './useCheckPasswordStrength/util';
import TooltipContent from './useCheckPasswordStrength/TooltipContent';
import FormikInput, { FormikInputProps } from '../FormikInput/FormikInput';
import { InputProps } from '../Input/Input';

interface PasswordStrengthInputProps extends InputProps {
  value: string;
  // input component can be any component that accepts value and onChange props or the Formik one
  InputComponent:
    | FC<InputHTMLAttributes<HTMLInputElement>>
    | typeof FormikInput;
  inputComponentProps?:
    | InputHTMLAttributes<HTMLInputElement>
    | FormikInputProps;
  className?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const PasswordStrengthInput = (props: PasswordStrengthInputProps) => {
  const { InputComponent, value, onChange, inputComponentProps, className } =
    props;
  const { strength, rulesStatus } = useCheckPasswordStrength(
    value,
    PASSWORD_RULES
  );

  const containerClasses = classNames('flex flex-col gap-2', className);

  return (
    <div className={containerClasses}>
      <Tooltip
        placement="right"
        tooltipContent={<TooltipContent rulesStatus={rulesStatus} />}
      >
        <InputComponent
          name="password"
          value={value}
          onChange={onChange}
          {...inputComponentProps}
        />
      </Tooltip>
      <PasswordStrength {...strength} />
    </div>
  );
};

export default PasswordStrengthInput;
