import { useQuery } from '@apollo/client';
import { useState } from 'react';
import PasswordStrengthInput from 'src/components/PasswordStrengthInput/PasswordStrengthInput';
import Input from 'src/components/Input/Input';
import { GET_LOCATIONS, Location } from '../api/location';
import Icon from '../components/Icon/Icon';
import { availableIcons } from '../components/Icon/types';

const Test = () => {
  const { loading, error, data } = useQuery(GET_LOCATIONS);
  const [password, setPassword] = useState('Alex123');

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error : {error.message}</p>;

  // TODO: We won't need this after we add GRAPHQL_TS
  // eslint-disable-next-line prefer-destructuring
  const locations: Location[] = data.locations;

  return (
    <div className="flex flex-1 flex-col">
      <h1 className="text-primary text-display-l leading-display-l text-center">
        Terra quantum UI
      </h1>
      <h1 className="text-display-l leading-display-l">Icons</h1>
      <div className="flex flex-wrap">
        {availableIcons.map((icon) => (
          <div
            className="flex flex-col gap-4 p-4 m-4 w-1/6 bg-surface-500 items-center"
            key={icon}
          >
            <Icon name={icon} width={32} height={32} />
          </div>
        ))}
      </div>

      <div className="flex m-2 p-2">
        <PasswordStrengthInput
          value={password}
          InputComponent={Input}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      {locations.map(({ id, name, description, photo }) => (
        <div key={id} className="flex flex-col gap-4 p-4 m-4">
          <h3 className="text-secondary text-title-m leading-title-m">
            {name}
          </h3>
          <img
            className="rounded-md shadow-md border-2 border-secondary-tint100"
            width="400"
            height="250"
            alt="location-reference"
            src={`${photo}`}
          />
          <br />
          <b className="text-white text-caption-l leading-caption-l">
            About this location:
          </b>
          <p className="text-chalk-500 text-body-l leading-body-l">
            {description}
          </p>
          <br />
        </div>
      ))}
    </div>
  );
};

export default Test;
