import { SVGProps } from 'react';

const Visibility = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="black" {...props}>
      <path
        d="M8 4a6.513 6.513 0 0 1 5.88 3.667A6.513 6.513 0 0 1 8 11.333a6.513 6.513 0 0 1-5.88-3.666A6.513 6.513 0 0 1 8 4Zm0-1.333c-3.333 0-6.18 2.073-7.333 5 1.153 2.926 4 5 7.333 5s6.18-2.074 7.333-5c-1.153-2.927-4-5-7.333-5ZM8 6a1.667 1.667 0 1 1 0 3.333A1.667 1.667 0 0 1 8 6Zm0-1.333c-1.653 0-3 1.346-3 3 0 1.653 1.347 3 3 3s3-1.347 3-3c0-1.654-1.347-3-3-3Z"
        fill="inherit"
      />
    </svg>
  );
};

export default Visibility;
