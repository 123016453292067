import { SVGProps } from 'react';

const Info = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 0.333374C3.32 0.333374 0.333336 3.32004 0.333336 7.00004C0.333336 10.68 3.32 13.6667 7 13.6667C10.68 13.6667 13.6667 10.68 13.6667 7.00004C13.6667 3.32004 10.68 0.333374 7 0.333374ZM7 12.3334C4.06 12.3334 1.66667 9.94004 1.66667 7.00004C1.66667 4.06004 4.06 1.66671 7 1.66671C9.94 1.66671 12.3333 4.06004 12.3333 7.00004C12.3333 9.94004 9.94 12.3334 7 12.3334ZM10.06 4.05337L5.66667 8.44671L3.94 6.72671L3 7.66671L5.66667 10.3334L11 5.00004L10.06 4.05337Z"
        fill="black"
      />
    </svg>
  );
};

export default Info;
