import { SVGProps } from 'react';

const Info = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.33333 3.66671H7.66666V5.00004H6.33333V3.66671ZM6.33333 6.33337H7.66666V10.3334H6.33333V6.33337ZM7 0.333374C3.32 0.333374 0.333328 3.32004 0.333328 7.00004C0.333328 10.68 3.32 13.6667 7 13.6667C10.68 13.6667 13.6667 10.68 13.6667 7.00004C13.6667 3.32004 10.68 0.333374 7 0.333374ZM7 12.3334C4.06 12.3334 1.66666 9.94004 1.66666 7.00004C1.66666 4.06004 4.06 1.66671 7 1.66671C9.93999 1.66671 12.3333 4.06004 12.3333 7.00004C12.3333 9.94004 9.93999 12.3334 7 12.3334Z"
        fill="black"
      />
    </svg>
  );
};

export default Info;
