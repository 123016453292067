interface PasswordStrengthProps {
  label: string;
  color: string;
  progress: string;
}

const PasswordStrength = (props: PasswordStrengthProps) => {
  const { label, color, progress } = props;

  return (
    <>
      <span className="text-ink-500">Password strength: {label}</span>
      <div className="relative pt-1">
        <div className="overflow-hidden h-2 text-xs flex rounded bg-ink-100">
          <div
            style={{ width: progress }}
            className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-${color}`}
          />
        </div>
      </div>
    </>
  );
};

export default PasswordStrength;
