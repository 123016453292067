import { Route, Routes } from 'react-router-dom';
import DemoPage from './pages/DemoPage';
import Layout from './Layout';
import Main from './pages/Main/Main';

import './globals.css';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Main />} />
        <Route path="/demo" element={<DemoPage />} />
      </Route>
    </Routes>
  );
};

export default App;
