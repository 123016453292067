import classNames from 'classnames';
import { InputHTMLAttributes, ReactNode, useRef } from 'react';
import Icon from 'src/components/Icon/Icon';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  labelClassName?: string;
  label?: string;
  leftClassName?: string;
  rightClassName?: string;
  clearable?: boolean;
  onClear?: () => void;
  left?: ReactNode;
  right?: ReactNode;
  error?: string;
}

const Input = ({
  containerClassName,
  labelClassName,
  label,
  leftClassName,
  rightClassName,
  clearable,
  onClear,
  left,
  right,
  className,
  error,
  ...props
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div>
      <label
        className={classNames(
          'block text-ink-500 text-body-s ',
          labelClassName
        )}
      >
        {label}
        <div className={classNames('flex-col relative', containerClassName)}>
          <input
            ref={inputRef}
            name="input"
            className={classNames(
              'block w-full mt-2 py-2 pl-10 bg-ink-0 ring-ink-200 ring-1 rounded text-body-l text-ink-900 focus:ring-3 focus:ring-primary-500 ',
              className,
              { 'ring-3 ring-danger': error }
            )}
            {...props}
          />
          <span
            className={`absolute top-1/2 -translate-y-1/2 z-10 left-2 ${leftClassName}`}
          >
            {left}
          </span>
          {clearable && (
            <button
              type="button"
              data-testid="clear-button"
              onClick={() => {
                if (!props.value && inputRef.current) {
                  inputRef.current.value = '';
                }
                onClear?.();
              }}
              className={classNames(
                'absolute top-1/2 -translate-y-1/2 z-10 flex justify-center right-2',
                {
                  'right-10': right,
                }
              )}
            >
              <Icon name="Close" className="fill-ink-500 hover:fill-ink-300" />
            </button>
          )}
          {right && (
            <span
              className={classNames(
                'absolute top-1/2 -translate-y-1/2 z-10 right-2',
                rightClassName
              )}
            >
              {right}
            </span>
          )}
        </div>
      </label>
      {error && (
        <div className="mt-2 text-danger text-caption-l tracking-tightest leading-5 font-medium">
          {error}
        </div>
      )}
    </div>
  );
};

export default Input;
