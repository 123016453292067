import Icon from 'src/components/Icon/Icon';
import { Rule } from './util';

interface TooltipContentProps {
  rulesStatus: Rule[];
}

const getRule = (rule: string, rulesStatus: Rule[]) => {
  return rulesStatus?.find((r) => r.id === rule);
};
const TooltipContent = (props: TooltipContentProps) => {
  const { rulesStatus } = props;
  const minLenghtRule = getRule('min-length', rulesStatus);
  const filterMinLength = (r: Rule) => r.id !== 'min-length';

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-ink-900 text-title-m leading-title-m">
        Password requirements
      </h3>
      <div className="flex items-center gap-2">
        <Icon name={minLenghtRule?.checked ? 'Check' : 'Close'} fill="white" />
        <p className="text-chalk-500 text-body-l leading-body-l">
          {minLenghtRule?.label}
        </p>
      </div>
      <div className="flex items-center gap-2">
        <Icon
          fill="white"
          name={
            rulesStatus.filter(filterMinLength).every((r) => r.checked)
              ? 'Check'
              : 'Close'
          }
        />
        <p className="text-chalk-500 text-body-l leading-body-l">
          Should contain
        </p>
      </div>
      <ul>
        {rulesStatus.filter(filterMinLength).map((rule) => (
          <li key={rule.id} className="ml-5">
            <div className="flex items-center gap-2">
              <Icon name={rule.checked ? 'Check' : 'Close'} fill="white" />
              <p className="text-chalk-500 text-body-l leading-body-l">
                {rule.label}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TooltipContent;
