import { SVGProps } from 'react';

const Minus = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 10 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.66667 1.66536H0.333332V0.332031H9.66667V1.66536Z"
        fill="black"
      />
    </svg>
  );
};

export default Minus;
