import { SVGProps } from 'react';

const ClearCircleOutline = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.82842 5.11439L8.88561 4.17158L7 6.0572L5.11438 4.17158L4.17157 5.11439L6.05719 7.00001L4.17157 8.88563L5.11438 9.82844L7 7.94282L8.88561 9.82844L9.82842 8.88563L7.9428 7.00001L9.82842 5.11439ZM11.714 2.28597C9.11189 -0.316188 4.8881 -0.316188 2.28595 2.28597C-0.316203 4.88812 -0.316203 9.1119 2.28595 11.7141C4.8881 14.3162 9.11189 14.3162 11.714 11.7141C14.3162 9.1119 14.3162 4.88812 11.714 2.28597ZM3.22876 10.7712C1.14987 8.69235 1.14987 5.30767 3.22876 3.22877C5.30765 1.14988 8.69234 1.14988 10.7712 3.22877C12.8501 5.30767 12.8501 8.69235 10.7712 10.7712C8.69234 12.8501 5.30765 12.8501 3.22876 10.7712Z"
        fill="black"
      />
    </svg>
  );
};

export default ClearCircleOutline;
