import { SVGProps } from 'react';

const Person = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.00001 1.99996C6.73334 1.99996 7.33334 2.59996 7.33334 3.33329C7.33334 4.06663 6.73334 4.66663 6.00001 4.66663C5.26667 4.66663 4.66667 4.06663 4.66667 3.33329C4.66667 2.59996 5.26667 1.99996 6.00001 1.99996ZM6.00001 8.66663C7.80001 8.66663 9.86667 9.52663 10 9.99996H2.00001C2.15334 9.51996 4.20667 8.66663 6.00001 8.66663ZM6.00001 0.666626C4.52667 0.666626 3.33334 1.85996 3.33334 3.33329C3.33334 4.80663 4.52667 5.99996 6.00001 5.99996C7.47334 5.99996 8.66667 4.80663 8.66667 3.33329C8.66667 1.85996 7.47334 0.666626 6.00001 0.666626ZM6.00001 7.33329C4.22001 7.33329 0.666672 8.22663 0.666672 9.99996V11.3333H11.3333V9.99996C11.3333 8.22663 7.78001 7.33329 6.00001 7.33329Z"
        fill="black"
      />
    </svg>
  );
};

export default Person;
